//global
import React, { useCallback, useEffect, useState } from 'react';
//local

//lib
import ModalWrapper from 'src/helpers/commonComponents/ModalWrapper';
import { warningUnsavedChangesTailwindVariant } from 'tailwindVariants/components/warningUnsavedChangesTailwindVariant';
import { createOrder, refreshOrder, setRecentPlacedOrder } from 'src/redux/ocCurrentOrder';
import { useOcDispatch } from 'src/redux/ocStore';
import { unwrapResult } from '@reduxjs/toolkit';
import { useCheckoutFormContext } from 'lib/context/CheckoutFormContext';
import { useRouter } from 'next/router';
import { IntegrationEvents } from 'ordercloud-javascript-sdk';
import useDictionary from 'src/hooks/useDictionary';
import { useRealPathName } from 'lib/utils/use-real-pathname';
import { currentPath } from 'src/helpers/Constants';

interface CurrentOrderStatus {
  showModal?: boolean;
  success?: boolean;
  error?: boolean;
  timeRemaining: number;
}

export interface SubmittingOrder {
  OrderId: string;
  SubmitTime: string;
}

//main component
const WarningSubmittingCart: React.FC = () => {
  const { base, wrapper, btnWrapper, btnPrimary, btnSecondary } =
    warningUnsavedChangesTailwindVariant({
      size: { initial: 'mobile', lg: 'desktop' },
    });

  const dispatch = useOcDispatch();
  const [orderStatus, setOrderStatus] = useOrderStatus();

  const dict = useDictionary();

  const orderSubmittingMainMessage = dict.getDictionaryValue(
    'OrderSubmittingWarning_MainMessage',
    'Previous order is being submitted. Please wait.'
  );

  const orderSubmittingTimeRemaining = dict
    .getDictionaryValue(
      'OrderSubmittingWarning_TimeRemaining',
      'Order status will be checked for the next $secondsRemaining second(s).'
    )
    .replace('$secondsRemaining', Math.round(orderStatus.timeRemaining / 1000).toString());

  const orderSubmittingTimeoutMessage = dict.getDictionaryValue(
    'OrderSubmittingWarning_TimeoutMessage',
    'Unable to verify status of order. Please check with store.'
  );

  const orderSubmittingErrorMessage = dict.getDictionaryValue(
    'OrderSubmittingWarning_ErrorMessage',
    'An error occurred submitting the order.'
  );

  const orderSubmittingNewOrderButton = dict.getDictionaryValue(
    'OrderSubmittingWarning_NewOrderButton',
    'Start New Order'
  );

  const orderSubmittingUpdatePaymentButton = dict.getDictionaryValue(
    'OrderSubmittingWarning_UpdatePaymentButton',
    'Update payment info and try again'
  );
  return (
    <ModalWrapper
      showModal={orderStatus?.showModal}
      additionalClassForPopUpModal={`${base({
        className: '',
      })} !item-start !h-fit translate-y-0  animate-[topAnimation_0.3s_ease-in-out]`}
      popupWidth="max-w-[995px]"
      showCloseButtonInModalHeader={false}
      closeModalOnOverlayClick={false}
      popupBG="bg-[rgba(0,0,0,0.95)]"
      customPopup
    >
      <div className={wrapper()}>
        {orderStatus.error ? (
          <div>{orderSubmittingErrorMessage}</div>
        ) : orderStatus.timeRemaining <= 0 ? (
          <div>{orderSubmittingTimeoutMessage}</div>
        ) : (
          <div>
            <p>{orderSubmittingMainMessage}</p>
            <p>{orderSubmittingTimeRemaining}</p>
          </div>
        )}

        <div className={btnWrapper()}>
          {orderStatus.error || orderStatus.timeRemaining <= 0 ? (
            <button
              aria-label="primary link"
              className={btnPrimary()}
              onClick={(e) => {
                e.preventDefault();
                dispatch(createOrder());
                deleteSubmittingOrder();
                setOrderStatus({
                  ...orderStatus,
                  showModal: false,
                  success: false,
                  error: false,
                });
              }}
            >
              {orderSubmittingNewOrderButton}
            </button>
          ) : null}

          {orderStatus.error ? (
            <button
              aria-label="secondary link"
              className={btnSecondary()}
              onClick={(e) => {
                e.preventDefault();
                setOrderStatus({
                  ...orderStatus,
                  showModal: false,
                  success: false,
                  error: false,
                });
              }}
            >
              {orderSubmittingUpdatePaymentButton}
            </button>
          ) : null}
        </div>
      </div>
    </ModalWrapper>
  );
};

export default WarningSubmittingCart;

function useOrderStatus() {
  const [orderStatus, setOrderStatus] = useState<CurrentOrderStatus>({ timeRemaining: 0 });
  // const currentOrder = useOcCurrentOrder();
  // const order = currentOrder.order;
  const dispatch = useOcDispatch();

  const { checkoutContextData, updateCheckoutContextData } = useCheckoutFormContext();

  const pathName = useRealPathName();
  const router = useRouter();

  const updateOrderStatus = useCallback(async () => {
    let [showModal, success, error] = [undefined, undefined, undefined] as (boolean | undefined)[];
    const submittingOrder = checkoutContextData.submittingOrder;

    if (submittingOrder && !checkoutContextData.checkoutIsSubmitting) {
      const worksheet = unwrapResult(await dispatch(refreshOrder()));
      const order = worksheet?.Order;
      if (submittingOrder?.OrderId === order?.ID) {
        showModal = true;
        if (order?.IsSubmitted) {
          success = true;
        }
        if (order?.xp?.Status === 'Problem') {
          error = true;
        }
      } else {
        success = true;
      }
    }

    if (success && submittingOrder?.OrderId) {
      showModal = false;
      const submittedWorksheet = await IntegrationEvents.GetWorksheet(
        'All',
        submittingOrder.OrderId
      );
      dispatch(setRecentPlacedOrder(submittedWorksheet));
      if (pathName === currentPath?.isCheckout) {
        // If we're on checkout page
        const onRouteChangeComplete = () => {
          deleteSubmittingOrder();
          updateCheckoutContextData({ submittingOrder: undefined });
          router.events.off('routeChangeComplete', onRouteChangeComplete);
        };
        router.events.on('routeChangeComplete', onRouteChangeComplete);

        const redirectUrl = `/checkout/orderconfirmation?id=${submittedWorksheet.Order?.xp?.OrderId}`;
        router.push(redirectUrl);
      } else {
        deleteSubmittingOrder();
        updateCheckoutContextData({ submittingOrder: undefined });
      }
    }

    setOrderStatus((orderStatus) => {
      const newOrderStatus = {
        showModal: showModal ?? orderStatus.showModal,
        success: success ?? orderStatus.success,
        error: error ?? orderStatus.error,
        timeRemaining:
          orderStatus.timeRemaining === 0
            ? getTimeRemaining(submittingOrder)
            : orderStatus.timeRemaining,
      };

      return newOrderStatus;
    });
  }, [
    checkoutContextData.checkoutIsSubmitting,
    checkoutContextData.submittingOrder,
    dispatch,
    pathName,
    router,
    updateCheckoutContextData,
  ]);

  useEffect(() => {
    const timeout = setInterval(updateOrderStatus, 5000);
    // Execute immediately
    updateOrderStatus();
    return () => {
      clearInterval(timeout);
    };
  }, [checkoutContextData.checkoutIsSubmitting, dispatch, router, updateOrderStatus]);

  const updateSubmittingOrderAndTime = useCallback(
    (timeout: NodeJS.Timeout) => {
      const submittingOrder = getSubmittingOrder();
      if (checkoutContextData.submittingOrder?.OrderId !== submittingOrder?.OrderId) {
        updateCheckoutContextData({
          submittingOrder,
        });
      }

      const timeRemaining = getTimeRemaining(submittingOrder);

      if (checkoutContextData.checkoutIsSubmitting && timeRemaining === 0) {
        clearInterval(timeout);
        updateOrderStatus();
      }

      setOrderStatus((orderStatus) => {
        const newOrderStatus = {
          ...orderStatus,
          timeRemaining: timeRemaining,
        };

        return newOrderStatus;
      });
    },
    [checkoutContextData.checkoutIsSubmitting, updateCheckoutContextData, updateOrderStatus]
  );

  useEffect(() => {
    // Seems the type is needed?  Seems to be fine locally but broke on build server
    const timeout: NodeJS.Timeout = setInterval(() => updateSubmittingOrderAndTime(timeout), 1000);

    return () => {
      clearInterval(timeout);
    };
  }, [updateOrderStatus, checkoutContextData.checkoutIsSubmitting, updateSubmittingOrderAndTime]);

  return [orderStatus, setOrderStatus] as const;
}

function getTimeRemaining(submittingOrder: SubmittingOrder | undefined) {
  if (!submittingOrder?.SubmitTime) {
    return 0;
  }
  const date = new Date(submittingOrder.SubmitTime);
  const timeoutDate = new Date(date.getTime() + 1 * 60 * 1000);
  const remaining = timeoutDate.getTime() - new Date().getTime();
  return Math.max(remaining, 0);
}

export function getSubmittingOrder() {
  if (typeof window.localStorage === 'undefined') {
    return undefined;
  }
  const orderString = localStorage.getItem('submittingOrder') ?? 'null';

  const submittingOrder = JSON.parse(orderString) as SubmittingOrder | undefined;
  return submittingOrder;
}

export function setSubmittingOrder(orderId: string) {
  if (typeof window.localStorage === 'undefined') {
    throw new Error('Cannot set submitting order from server side');
  }
  localStorage.setItem(
    'submittingOrder',
    JSON.stringify({ OrderId: orderId, SubmitTime: new Date() })
  );
}

export function deleteSubmittingOrder() {
  localStorage.removeItem('submittingOrder');
}
