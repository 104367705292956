import { useTheme } from 'lib/context/ThemeContext';
import React from 'react';

const MaintenanceMode = () => {
  const { themeNameUpper } = useTheme();
  const isPSP = themeNameUpper === 'PSP';

  return (
    <div
      id="maintenanceMode"
      className={`${isPSP ? themeNameUpper?.toLowerCase() : 'wnw'} px-[24px]`}
    >
      <div id="article">
        {isPSP ? (
          <h1 className={'text-[50px] font-bold text-color-brand-primary-1-base'}>
            Pets Supplies Plus
          </h1>
        ) : (
          <h1 className="text-[50px] font-bold text-color-brand-primary-1-base">Wag N Wash</h1>
        )}
        <h2>Our website is currently down for maintenance</h2>
        <div className="mt-2">
          <p>{`We apologize for the inconvenience, but we'll be back up soon!`}</p>
        </div>
      </div>
    </div>
  );
};

export default MaintenanceMode;
