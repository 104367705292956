import React, { MouseEventHandler, useEffect } from 'react';
// import ReactDOM from 'react-dom';
// import clsx from 'clsx';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { tv } from 'tailwind-variants';
import clsx from 'clsx';
import IconHelper from './IconHelper';
import { useHeaderContext } from 'lib/context/HeaderComponentContext';

// export type ModalWrapperProps = {
//   rendering?: { componentName: string };
//   params?: { [key: string]: string };
//   fields: Props;
// };

export interface ModalWrapperProps extends React.PropsWithChildren {
  title?: Field<string>;
  closeModalOnOverlayClick?: boolean;
  onCloseClick?: MouseEventHandler;
  onSubmitClick?: MouseEventHandler;
  showCloseButtonInModalHeader?: boolean;
  showSubmitButton?: boolean;
  showCancelButton?: boolean;
  showModal?: boolean;
  customPopup?: boolean;
  buttonsPosition?: string;
  popupSpacing?: string;
  popupWidth?: string;
  popupBG?: string;
  additionalClassForModal?: string;
  additionalClassForPopUpModal?: string;
  showSmallPopup?: boolean;
  componentName?: string;
  isMiniCart?: boolean;
}
const ModalWrapper: React.FC<ModalWrapperProps> = ({
  closeModalOnOverlayClick = false,
  onCloseClick,
  showModal,
  customPopup,
  popupSpacing,
  popupWidth,
  popupBG,
  additionalClassForPopUpModal,
  showCloseButtonInModalHeader = true,
  showSmallPopup,
  componentName,
  isMiniCart,
  ...props
}: ModalWrapperProps) => {
  // const [element, setElement] = useState<HTMLDivElement | null>(null);
  const { headerContextData } = useHeaderContext();
  useEffect(() => {
    if (showModal) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, [showModal]);
  const ModalWrapperTV = tv({
    slots: {
      modalWrapperBase: [
        'fixed',
        'z-[950]',
        'flex',
        'h-screen',
        'w-screen',
        'justify-center',
        'items-center',
        'top-0',
        'left-0',
        'bottom-0',
        'right-0',
      ],
      modalWrapperInner: [
        'z-50',
        'h-full',
        'w-full',
        'bg-transparent',
        'rounded',
        'py-12',
        'px-4',
        'lg:py-10',
        'absolute',
        'overflow-auto',
        'left-0',
        'top-0',
      ],
      modalWrapperCloseIcon: [
        'absolute',
        'z-[1]',
        'right-2',
        'top-2',
        'lg:right-4',
        'lg:top-4',
        'cursor-pointer',
        'w-6',
        'h-6',
        'lg:w-10',
        'lg:h-10',
      ],
      modalWrapperCloseIconHelper: [
        'iconWrapper',
        '&>svg',
        'w-6',
        '&>svg',
        'h-6',
        '&>svg',
        'lg:w-10',
        '&>svg',
        'lg:h-10',
      ],
    },
  });
  const {
    modalWrapperBase,
    modalWrapperInner,
    modalWrapperCloseIcon,
    modalWrapperCloseIconHelper,
  } = ModalWrapperTV({});

  // if (!showModal) { // Removed this as it was causing issue as this modal popup is required to present on initial load.
  //   return <></>;
  // }
  return (
    <div
      className={clsx(
        modalWrapperBase(),
        `${!isMiniCart && componentName}`,
        !showSmallPopup && (popupBG && !showSmallPopup ? popupBG : 'bg-[rgba(0,0,0,0.5)]'),
        headerContextData?.openMiniCart === true ? 'bg-[rgba(0,0,0,0.5)]' : '',
        { block: showModal, hidden: !showModal },
        props?.additionalClassForModal
      )}
      {...(closeModalOnOverlayClick && onCloseClick && { onClick: onCloseClick })}
    >
      <div
        className={clsx(
          modalWrapperInner(),
          showSmallPopup !== true ? 'lg:px-20' : 'py-4 lg:px-[55px]'
        )}
      >
        {showCloseButtonInModalHeader && (
          <a aria-label="modal close" className={modalWrapperCloseIcon()} onClick={onCloseClick}>
            {<IconHelper className={modalWrapperCloseIconHelper()} icon={'close'} />}
          </a>
        )}
        {customPopup ? (
          <div
            className={`flex h-full w-full ${additionalClassForPopUpModal} ${
              showSmallPopup !== true
                ? 'justify-center items-center'
                : 'justify-end lg:items-end items-start'
            }`}
          >
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
              className={`bg-color-background-white modal-wrapper rounded-xl overflow-y-auto max-h-full w-full relative ${
                popupWidth ? popupWidth : 'max-w-[526px]'
              } ${
                popupSpacing
                  ? popupSpacing
                  : 'lg:py-desk-padding-tight-y py-mob-padding-tight-y lg:px-desk-padding-tight-x px-mob-padding-tight-x'
              } `}
            >
              {showCloseButtonInModalHeader && (
                <a
                  aria-label="close link"
                  className="absolute z-[1] right-2 top-2 lg:right-2 lg:top-2 cursor-pointer w-8 h-8 "
                  onClick={onCloseClick}
                >
                  {
                    <IconHelper
                      className="iconWrapper [&>svg]:w-8 [&>svg]:h-8 [&>svg_*]:fill-color-background-brand-1"
                      icon={'close'}
                    />
                  }
                </a>
              )}
              {props.children}
            </div>
          </div>
        ) : (
          <div
            className="flex h-full [&>div]:w-full "
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {props.children}
          </div>
        )}
      </div>
    </div>
  );
};
export default ModalWrapper;
