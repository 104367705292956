/* eslint-disable @typescript-eslint/no-explicit-any */
//global
import React, { useContext, useEffect, useRef, useState } from 'react';
//local
import { ComponentProps } from 'lib/component-props';

import { PSP } from 'models/PetSuppliesPlus.Model';
import useOcCart from 'src/hooks/useOcCart';
import { LineItem } from 'ordercloud-javascript-sdk';
import { NextImage, Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import clsx from 'clsx';
import ComponentContext from 'lib/context/ComponentContext';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import { removeLineItem, calculateOrder } from 'src/redux/ocCurrentOrder';
import {
  FulfillmentType,
  GTMLabels,
  GTM_EVENT,
  currentPath,
  getProductDetailPath,
} from 'src/helpers/Constants';
import useDictionary from 'src/hooks/useDictionary';
import { BuyerProductWithXp, LineItemWithXp } from 'src/redux/xp';
import { analyseAuthForLoginCheckout } from 'src/helpers/LoginCheckout';
import { getGTMSessionStorage, sendProductsPromotion } from 'src/utils/sendGTMEvent';
import { ProductSearchResultModelWithVariants } from 'src/helpers/search/SearchResults/types';
import { checkoutPreflightChecks } from 'components/Checkout/helper/preflight';
import Link from 'next/link';
import { getFirstProductImage } from 'src/helpers/productDetailHelper';
import router from 'next/router';
import Loader from 'components/Loader/Loader';
import { useLineItemPriceForUI } from 'src/hooks/useLineItemPriceForUI';
import { currencyUsdFormatter } from 'lib/utils/formatters';

import { useCartPriceForUI } from 'src/hooks/useCartPriceForUI';
import { useIsSoftLoginEnabled } from 'src/hooks/useIsSoftLoginEnabled';
import { miniCartTailwindVariant } from 'tailwindVariants/components/MiniCartTailwindVariant';
import { useHeaderContext } from 'lib/context/HeaderComponentContext';
export type MiniCartProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.MiniCart.Fields.MiniCart;

const MiniCart = (props: MiniCartProps): JSX.Element => {
  const {
    title,
    itemsText,
    merchandiseSubtotal,
    loaderText,
    checkoutLink,
    viewOrEditLink,
    deliveryText,
  } = props?.fields ?? {};

  const subTotal = useOcSelector((state: any) => state?.ocCurrentOrder?.order);
  const ocCurrentOrderObject = useOcSelector((state) => state?.ocCurrentOrder);
  const myStoreData = useOcSelector((state: any) => state?.storeReducer?.selectedStore);

  const {
    numberOfItemsInCart,
    getProductLineItems,
    numberOfItemsInCartSoftLogin,
    getProductLineItemsSoftLogin,
  } = useOcCart();

  const productlineitems: LineItemWithXp[] = getProductLineItems();
  const [localProductLineItems, setLocalProductLineItems] = useState<LineItemWithXp[]>([]);
  const [isDFS, setIsDFS] = useState<boolean>();
  // const { lineItems } = useOcCurrentOrder();

  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const { headerContextData, setHeaderContextData } = useHeaderContext();
  const isSoftLoginEnabled = useIsSoftLoginEnabled();
  const [cartSubTotal, setCartSubTotal] = useState<number>(0);
  const cartPricing = useCartPriceForUI(ocCurrentOrderObject);
  const [newSubTotalForRender, setNewSubTotalForRender] = useState<number>(0);

  useEffect(() => {
    const newSubTotal = isSoftLoginEnabled ? cartSubTotal : cartPricing?.subTotal;
    setNewSubTotalForRender(newSubTotal);
  }, [isSoftLoginEnabled, cartPricing?.subTotal, cartSubTotal]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Call preflight check asynchronously if it returns a Promise
      const callPreflightCheckAsync = async () => {
        await callPreflightCheck();
      };
      callPreflightCheckAsync();

      if (isSoftLoginEnabled) {
        const softLoginDependentData = JSON.parse(
          sessionStorage.getItem('softlogin_dependencies') || '{}'
        );
        const localItems = sessionStorage.getItem('cartLineItems');

        if (localItems) {
          const getProductLineItemsSoftLoginAsync = async () => {
            const res = await getProductLineItemsSoftLogin();
            setLocalProductLineItems(res);
            setCartSubTotal(softLoginDependentData?.subtotal ?? cartPricing?.subTotal);
          };
          getProductLineItemsSoftLoginAsync();
        }
      } else {
        setCartSubTotal(cartPricing?.subTotal);
      }
    }
  }, [headerContextData.openMiniCart, headerContextData.showMiniCartLoader, isSoftLoginEnabled]);

  const {
    miniCartWrapper,
    miniCartTitle,
    merchandiseTotal,
    miniCartItemsInCart,
    buttonStyles,
    buttonPrimaryStyle,
    miniCartLineItemsWrapper,
    miniCartLoaderWrapper,
    miniCartLoaderText,
    miniCartBottomWrapper,
    miniCartBottomWrapperLink,
    viewMoreIcon,
  } = miniCartTailwindVariant({});

  const [miniCartLoader, setminiCartLoader] = useState(false);
  const [passesPreflightCheck, setPassesPreflightCheck] = useState(true);

  useEffect(() => {
    setcomponentContextData({ ...componentContextData, showDeliveryThreashold: false });
  }, []);

  const callPreflightCheck = async () => {
    try {
      const preflightResponse = await checkoutPreflightChecks(productlineitems);
      setPassesPreflightCheck(preflightResponse);
    } catch (e) {
      console.error('Error during preflight check:', e);
    }
  };

  useEffect(() => {
    if (newSubTotalForRender > 0) {
      callPreflightCheck();
    }
  }, [newSubTotalForRender]);

  useEffect(() => {
    headerContextData?.showMiniCartLoader == true
      ? setminiCartLoader(true)
      : setminiCartLoader(false);
    callPreflightCheck();
  }, [headerContextData?.showMiniCartLoader]);

  const handleShowWarningPopup = () => {
    if (passesPreflightCheck) {
      // if (!componentContextData.showDeliveryThreashold) {
      setcomponentContextData({ ...componentContextData, showDeliveryThreashold: true });
    }
    // }
  };

  const isAnonymous = useOcSelector((s) => s.ocAuth.isAnonymous);
  const handleUserCheck = (redirectLink: string) => {
    if (passesPreflightCheck) {
      const response = analyseAuthForLoginCheckout(isAnonymous, redirectLink);
      if (response?.redirectURL) {
        setHeaderContextData({
          ...headerContextData,
          openMiniCart: false,
          showMiniCartLoader: false,
          outOfStockProductIds: [],
        });
        router.push(response?.redirectURL);
      }
    }
  };
  const cart = useOcSelector((state) => state?.ocCurrentOrder?.order);

  useEffect(() => {
    const newMethod = localStorage.getItem('selected_fulfillment_Method') || cart?.xp?.Fulfillment;
    setIsDFS(newMethod === FulfillmentType.DFS);
  }, [cart?.xp?.Fulfillment, componentContextData?.isDFSMethodChanged]);

  // Renders the Item count based on varios conditions including SoftLogin:
  const RenderItemCount: React.FC = () => {
    const cartCount = isSoftLoginEnabled
      ? numberOfItemsInCartSoftLogin() !== undefined
        ? numberOfItemsInCartSoftLogin()
        : '0'
      : numberOfItemsInCart() !== undefined
      ? numberOfItemsInCart()
      : '0';
    return <>{cartCount} </>;
  };

  return (
    <div className={miniCartWrapper()}>
      <p className={miniCartTitle()}>{title?.value}</p>
      <Placeholder name="progress-bar" rendering={props?.rendering} />
      {miniCartLoader && (
        <div className={miniCartLoaderWrapper()}>
          <Loader />
          <p className={miniCartLoaderText()}>{loaderText?.value}</p>
        </div>
      )}
      {/* Renders cart count line Item */}
      <p className={miniCartItemsInCart()}>
        {!isSoftLoginEnabled && <RenderItemCount />}
        {isSoftLoginEnabled && <RenderItemCount />}
        {!isDFS ? itemsText?.value : deliveryText?.value}
      </p>
      <div>
        <Placeholder name="sxa-warning-delivery-threshold" rendering={props?.rendering} />
        <Placeholder name="switch-to-delivery" rendering={props?.rendering} />
      </div>
      {(productlineitems?.length > 0 || localProductLineItems?.length > 0) && (
        <>
          <p className={merchandiseTotal()}>
            {merchandiseSubtotal?.value}: {currencyUsdFormatter.format(newSubTotalForRender)}
          </p>

          <div className="checkoutCTAWrapper">
            {subTotal?.xp?.Fulfillment !== FulfillmentType.BOPIS &&
            myStoreData?.deliveryFee?.minimumAmount > newSubTotalForRender ? (
              <LinkHelper
                //field={checkoutLink as any} // This needs a fix
                field={{
                  value: {
                    title: checkoutLink?.value?.text,
                    text: checkoutLink?.value?.text,
                    href: '/',
                  },
                }}
                className={clsx(
                  buttonStyles(),
                  buttonPrimaryStyle({ isDisabled: !passesPreflightCheck })
                )}
                onClick={(e) => {
                  e.preventDefault();
                  handleShowWarningPopup();
                }}
              />
            ) : (
              <LinkHelper
                //field={checkoutLink as any} // This needs a fix
                field={{
                  value: {
                    title: checkoutLink?.value?.text,
                    text: checkoutLink?.value?.text,
                    href: '/',
                  },
                }}
                className={clsx(
                  buttonStyles(),
                  buttonPrimaryStyle({ isDisabled: !passesPreflightCheck })
                )}
                onClick={(e) => {
                  e.preventDefault();
                  handleUserCheck(checkoutLink?.value?.href ?? '');
                }}
              />
            )}
          </div>
          <div className={miniCartLineItemsWrapper()}>
            {/* Render from the OC SDK call */}
            {!isSoftLoginEnabled &&
              productlineitems?.map((line: LineItem, index) => {
                return (
                  <MiniCartLineItem
                    key={line.ID}
                    line={line}
                    fields={props.fields}
                    index={index}
                    isDFS={isDFS || false}
                  />
                );
              })}
            {/* Render from the stored data from LocalStorage */}
            {isSoftLoginEnabled &&
              localProductLineItems?.map((line: LineItem, index) => {
                return (
                  <MiniCartLineItem
                    key={line.ID}
                    line={line}
                    fields={props.fields}
                    index={index}
                    isDFS={isDFS || false}
                  />
                );
              })}
          </div>
          <div className={miniCartBottomWrapper()}>
            <LinkHelper
              onClick={() => {
                setHeaderContextData({
                  ...headerContextData,
                  openMiniCart: false,
                  showMiniCartLoader: false,
                  outOfStockProductIds: [],
                });
              }}
              // field={viewOrEditLink as any}
              field={{
                value: {
                  ...viewOrEditLink,
                  href: !passesPreflightCheck ? '#' : viewOrEditLink?.value?.href,
                },
              }}
              className={miniCartBottomWrapperLink({ isDisabled: !passesPreflightCheck })}
            >
              <>
                <span>{viewOrEditLink?.value?.text}</span>
                <IconHelper icon={'chevron-right'} className={viewMoreIcon()} />
              </>
            </LinkHelper>
          </div>
        </>
      )}
    </div>
  );
};

function MiniCartLineItem({
  line,
  fields,
  index,
  isDFS,
}: {
  line: LineItemWithXp;
  fields: MiniCartProps['fields'];
  index: number;
  isDFS: boolean;
}) {
  const {
    miniCartLineItemDescription,
    miniCartItemImage,
    miniCartLineItem,
    productQtyWrapper,
    productQtyWrapperOuter,
    productQtyWrapperLeftInner,
    productStrikedRate,
    productFinalRate,
    productPriceWrapper,
    prodcutQtyWrapperRightInner,
    productQtyWrapperQtyLabel,
    productQtyInput,
    productQtyButtonWrapper,
    productQtyButton,
    removeLineItems,
    removeLineItemText,
    lineItemLoader,
    errorMessage,
    productListRate,
  } = miniCartTailwindVariant({});
  const { currentPage, pageItem } = getGTMSessionStorage();
  const { quantity, remove } = fields ?? {};

  const { getDictionaryValue } = useDictionary();

  // const priceforMinicart = useProductPriceForUI(line?.ProductID);
  const priceforMinicart = useLineItemPriceForUI(line, true);
  const [loadingItems, setLoadingItems] = useState(new Set());
  const inventoryMapRef = useRef(new Map());
  const [quantityMap, setQuantityMap] = useState(new Map());
  const [errorMap, setErrorMap] = useState(new Map());
  const { headerContextData, setHeaderContextData } = useHeaderContext();
  const myStoreData = useOcSelector((state: any) => state?.storeReducer?.selectedStore);

  //const isAnonymous = useOcSelector((s) => s.ocAuth.isAnonymous); Commenting out this for now because for price integration if member price is available than list price will always show as crossed off

  const dispatch = useOcDispatch();

  const { getProductAvailability, addToCart } = useOcCart();
  const isSoftLoginEnabled = useIsSoftLoginEnabled();

  const [qtyInputValue, setQtyInputValue] = useState(line?.Quantity || '');
  const delayRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    setQtyInputValue(line?.Quantity || '');
  }, [line?.Quantity]);

  const removeLine = async (ID: any, productID: any) => {
    setLoadingItems((prevLoadingItems) => new Set([...prevLoadingItems, productID]));

    if (ID) {
      await dispatch(removeLineItem(ID)).then(() => {
        dispatch(calculateOrder());
      });
    }
    setLoadingItems((prevLoadingItems) => {
      const newLoadingItems = new Set(prevLoadingItems);
      newLoadingItems.delete(productID);
      return newLoadingItems;
    });
    /**
     * Filter Session Storage upon Removal of Item:
     */
    // 1. Filter out session storage obj, cartLineItems from the Redux storage -
    const storageLineItems = JSON.parse(sessionStorage.getItem('cartLineItems') as any);
    // 2. update it.
    storageLineItems.filter((x: any) => {
      if (x.ID !== ID) {
        return x;
      }
    });
    sessionStorage.setItem('cartLineItems', JSON.stringify(storageLineItems));
  };

  const debounceDelay = (callback: () => void, delay: number) => {
    if (delayRef.current) {
      clearTimeout(delayRef.current);
    }

    delayRef.current = setTimeout(() => {
      callback();
    }, delay);
  };

  const handleQtyInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value === '') {
      setQtyInputValue('1');
      const newErrorMap = new Map(errorMap);
      newErrorMap.set(line?.ProductID, false);
      setErrorMap(newErrorMap);
    }

    const isValid = /^\d*$/.test(value);
    const numberValue = Number(value);

    if (isValid && numberValue > 0) {
      setQtyInputValue(value);
      debounceDelay(() => {
        handleQuantity(line?.ProductID, value);
      }, 300);
    }
  };

  const handleQuantity = async (productID: any, quantity: any) => {
    setLoadingItems((prevLoadingItems) => new Set([...prevLoadingItems, productID]));

    if (!inventoryMapRef.current.has(productID)) {
      const productInventory = await getProductAvailability(productID, myStoreData?.storeId);
      inventoryMapRef.current.set(productID, productInventory);
    }
    const productInventory = inventoryMapRef.current.get(productID);
    const inventoryItemId = productInventory?.inventoryRecordId;
    const response = await addToCart({
      productId: productID,
      quantity: quantity,
      storeId: myStoreData?.storeId,
      inventoryItemId: inventoryItemId,
      isUpdateLineItem: true,
      ID: `${productID}`, // To prevent racing condition
    });
    // TODO:
    if (response && typeof response === 'object' && 'error' in response) {
      const newErrorMap = new Map(errorMap);
      newErrorMap.set(productID, true);
      setHeaderContextData({
        ...headerContextData,
        openMiniCart: true,
        showMiniCartLoader: false,
        outOfStockProductIds: [
          ...((headerContextData?.outOfStockProductIds, productID ?? []) as string),
        ],
      });
      setErrorMap(newErrorMap);
    } else if (response === null && quantity !== 0) {
      const newErrorMap = new Map(errorMap);
      newErrorMap.set(productID, true);
      setHeaderContextData({
        ...headerContextData,
        openMiniCart: true,
        showMiniCartLoader: false,
        outOfStockProductIds: [
          ...((headerContextData?.outOfStockProductIds, productID ?? []) as string),
        ],
      });
      setErrorMap(newErrorMap);
    } else {
      const outOfStockProductIds = headerContextData?.outOfStockProductIds?.filter(
        (id) => id !== productID
      );
      setHeaderContextData({
        ...headerContextData,
        openMiniCart: true,
        showMiniCartLoader: false,
        outOfStockProductIds: outOfStockProductIds,
      });
      const newErrorMap = new Map(errorMap);
      newErrorMap.set(productID, false);
      setErrorMap(newErrorMap);
      const newQuantityMap = new Map(quantityMap);

      newQuantityMap.set(productID, quantity);
      setQuantityMap(newQuantityMap);

      setQtyInputValue(quantity === 0 ? 1 : quantity);
    }
    setLoadingItems((prevLoadingItems) => {
      const newLoadingItems = new Set(prevLoadingItems);
      newLoadingItems.delete(productID);
      return newLoadingItems;
    });
  };

  const productUrlClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setHeaderContextData({
      ...headerContextData,
      openMiniCart: false,
      showMiniCartLoader: false,
      outOfStockProductIds: [],
    });
    router.push(productURL);
  };
  const productURL = getProductDetailPath(line?.Product as BuyerProductWithXp);
  const imageData = getFirstProductImage(line?.Product?.xp?.Images);

  return (
    <>
      <div className={miniCartLineItem()}>
        <div>
          <Link onClick={productUrlClick} aria-label="product url" href={productURL}>
            <NextImage
              className={miniCartItemImage()}
              field={{
                value: {
                  src: imageData?.Url,
                },
              }}
              alt={imageData?.Name}
              width="69"
              height="88"
            />
          </Link>
        </div>
        <div>
          <Link aria-label="product url" onClick={productUrlClick} href={productURL}>
            <div className={miniCartLineItemDescription()}>{line?.Product?.Name}</div>
          </Link>
          {((headerContextData?.outOfStockProductIds &&
            headerContextData?.outOfStockProductIds?.includes(line.ProductID)) ||
            errorMap.get(line.ProductID)) && (
            <p className={errorMessage()}>{getDictionaryValue('OutOfStockMessage')}</p>
          )}
          {line.Product?.xp?.WE == true && (
            <p className={errorMessage()}>{getDictionaryValue('minicartpriceexcludecheck')}</p>
          )}
          <div className={productQtyWrapperOuter()}>
            <div className={productQtyWrapper()}>
              <div className={productQtyWrapperLeftInner()}>
                <span className={productQtyWrapperQtyLabel()}>{quantity?.value}.</span>
                {loadingItems.has(line.ProductID) && <Loader className={lineItemLoader()} />}
                <>
                  <label htmlFor="qtyInput"></label>
                  <input
                    aria-label="qtyInput"
                    name="qtyInput"
                    className={productQtyInput()}
                    type="text"
                    inputMode="numeric"
                    value={qtyInputValue}
                    onChange={(e) => {
                      const quantity = e.target?.value;
                      const isError = errorMap.get(line.ProductID);

                      // Allow clearing the input by setting an empty string when cleared
                      if (isError) {
                        setQtyInputValue(quantity);
                      } else if (quantity === '') {
                        setQtyInputValue(''); // Keep it as a number, but display as empty
                      } else if (quantity > '0') {
                        setQtyInputValue(quantity);
                      } else if (qtyInputValue === 0) {
                        setQtyInputValue('1');
                      } else {
                        setQtyInputValue(Number(quantity)); // Convert to a number if not empty
                      }
                    }}
                    onBlur={handleQtyInputChange}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        // Reset to 1 if empty or 0 on Enter key press

                        if (qtyInputValue === 0 || qtyInputValue === '') {
                          setQtyInputValue('1');
                          handleQuantity(line?.ProductID, '1');
                        } else {
                          handleQuantity(line?.ProductID, qtyInputValue);
                        }
                      }
                    }}
                  />
                </>
              </div>

              <div className={productQtyButtonWrapper()}>
                <button
                  aria-label="add to cart"
                  className={productQtyButton()}
                  onClick={async (e) => {
                    e.preventDefault();
                    if (isSoftLoginEnabled) {
                      setHeaderContextData({
                        ...headerContextData,
                        openMiniCart: false,
                        showMiniCartLoader: false,
                        outOfStockProductIds: [],
                      });
                      router.push(currentPath?.isLogin);
                    } else {
                      const newQty = line?.Quantity && line?.Quantity + 1;
                      await handleQuantity(line?.ProductID, newQty);
                      sendProductsPromotion({
                        eventName: GTM_EVENT?.addToCart,
                        data: {
                          ...line?.Product,
                          BasePrice:
                            priceforMinicart?.lineItemMemberPrice ||
                            priceforMinicart?.lineItemListPrice,
                        } as ProductSearchResultModelWithVariants,
                        currentPage: currentPage,
                        pageItem: pageItem,
                        position: index ?? 0,
                        click_from: GTMLabels?.fromMiniCartPlusSign,
                        quantity: 1,
                        parentId: line?.Product?.ParentID,
                        listPrice: line?.UnitPrice,
                        isRatingShow: true,
                        fulfillment_option: isDFS ? GTMLabels?.DFS : GTMLabels?.BOPIS,
                      });
                    }
                  }}
                >
                  +
                </button>
                <button
                  aria-label="remove from cart"
                  className={productQtyButton()}
                  onClick={async (e) => {
                    e.preventDefault();
                    if (isSoftLoginEnabled) {
                      setHeaderContextData({
                        ...headerContextData,
                        openMiniCart: false,
                        showMiniCartLoader: false,
                        outOfStockProductIds: [],
                      });
                      router.push(currentPath?.isLogin);
                    } else {
                      const newQty = line?.Quantity && line?.Quantity - 1;

                      await handleQuantity(line?.ProductID, newQty);
                      sendProductsPromotion({
                        eventName: GTM_EVENT?.removeFromCart,
                        data: {
                          ...line?.Product,
                          BasePrice:
                            priceforMinicart?.lineItemMemberPrice ||
                            priceforMinicart?.lineItemListPrice,
                        } as ProductSearchResultModelWithVariants,
                        currentPage: currentPage,
                        pageItem: pageItem,
                        position: index ?? 0,
                        click_from: GTMLabels?.fromMiniCartMinus,
                        quantity: 1,
                        isRatingShow: true,
                        parentId: line?.Product?.ParentID,
                        listPrice: line?.UnitPrice,
                        fulfillment_option: isDFS ? GTMLabels?.DFS : GTMLabels?.BOPIS,
                      });
                    }
                  }}
                >
                  -
                </button>
              </div>
            </div>

            <div className={prodcutQtyWrapperRightInner()}>
              {priceforMinicart?.productPriceLoader ? (
                <div className={productPriceWrapper()}>
                  {/* List Price  */}

                  {priceforMinicart?.lineItemMemberPrice !== undefined ? (
                    <>
                      <div className="list-price">
                        <span className={clsx(productListRate(), productStrikedRate())}>
                          {currencyUsdFormatter.format(priceforMinicart?.lineItemListPrice ?? 0)}
                        </span>
                      </div>
                      <div className="ppc-price">
                        <span className={clsx(productFinalRate())}>
                          {currencyUsdFormatter.format(priceforMinicart?.lineItemMemberPrice ?? 0)}
                        </span>
                      </div>
                    </>
                  ) : (
                    priceforMinicart?.lineItemListPrice && (
                      <div className="ppc-price">
                        <span className={clsx(productListRate())}>
                          {currencyUsdFormatter.format(priceforMinicart?.lineItemListPrice ?? 0)}
                        </span>
                      </div>
                    )
                  )}
                </div>
              ) : (
                <Loader />
              )}
            </div>
          </div>
          <div className={removeLineItems()}>
            <span
              className={removeLineItemText()}
              onClick={async (e) => {
                e.preventDefault();
                await removeLine(line?.ID, line?.ProductID);
                sendProductsPromotion({
                  eventName: GTM_EVENT?.removeFromCart,
                  data: {
                    ...line?.Product,
                    BasePrice:
                      priceforMinicart?.lineItemMemberPrice || priceforMinicart?.lineItemListPrice,
                  } as ProductSearchResultModelWithVariants,
                  currentPage: currentPage,
                  pageItem: pageItem,
                  position: index ?? 0,
                  click_from: GTMLabels?.fromMiniCartRemove,
                  quantity: line?.Quantity,
                  parentId: line?.Product?.ParentID,
                  listPrice: line?.UnitPrice,
                  fulfillment_option: isDFS ? GTMLabels?.DFS : GTMLabels?.BOPIS,
                });
              }}
            >
              {remove?.value}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
export default MiniCart;
